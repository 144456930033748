*{
    margin: 0px;
    padding:0px;
    font-family:Arial,Helvetica Neue,Helvetica,sans-serif !important;
        // font-family: 'Poppins', sans-serif;
}

body
{
    background-color: #F4F7FA !important;  
}
