
.Sidebar {
    &__list{
        display: flex;
        flex-direction:column;
        gap: 5px;
    }
    &__subList{
        display: flex;
        flex-direction:column;
        gap: 5px;
    }
}
