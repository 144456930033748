.filter-dialog>div>div {
    border-radius: 20px !important;
    width: 700px !important;
    padding: 10px
    
}

.datepicker {
    border: 1px solid red !important;
}
.dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
  }

.Reports {
    // .MuiInputBase-root-MuiOutlinedInput-root {
    //     border-radius: 25px !important;
    // }

    // .MuiPaper-root {
    //     border-radius: 25px !important;
    //     margin-left: 35px !important;
    // }

    .item {
        display: inline-block;
        margin: 10px 0px;
        // background-color: #8ca0ff;
        width: 300px !important;

        //   padding: 5px;
        //   margin-bottom: 1em;
    }
    .MuiInputBase{
        border-radius: 25px;
    }
}
[data-title] {
	position: relative;
	cursor: help;
    font-size: 14px;
}

[data-title]:hover::before {
	content: attr(data-title);
	position: relative;
	background: #000;
	color: #fff;
	white-space: nowrap;
    margin-left: 135px;
    border-radius: 5px;
    top: 10px;
    height: 40px;
}